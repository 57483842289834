@font-face {
	font-family: 'Manrope';
	src: url('Manrope-Regular.woff') format('woff');
	font-weight: 1;
	font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('Manrope-Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('Manrope-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('Manrope-ExtraBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
